<template>
  <v-row no-gutters>
    <v-col cols="9">
      <v-row no-gutters>
        <v-col cols="12" class="font-weight-bold title_name" v-if="showName">
          {{ structureName }} {{ news.LIST_NewsType && news.LIST_NewsType.SlugTypeNews === 'direct_info' && ` - ${news.LIST_NewsType.NomTypeNews}` }}</v-col
        >
        <v-col cols="12" class="title_name">{{ body }}</v-col>
        <v-col cols="12">
          <div class="-posted">
            <font-awesome-icon icon="calendar-alt" />
            {{ posted_at }}
          </div>
        </v-col>
      </v-row>
    </v-col>
    <v-col
      cols="3"
      style="display:flex; flex-direction:row; align-items:center"
    >
      <v-btn
        @click.prevent="readNews"
        outlined
        color="#01abe8"
        style="text-transform:capitalize !important;"
      >
        Lire l'actualité
      </v-btn>
    </v-col>

    <modalNews
      v-if="openNewsModalIsOpen"
      :isOpen="openNewsModalIsOpen"
      @onClose="openNewsModalIsOpen = false"
      :news="news"
    />
  </v-row>
</template>

<script>
import modalNews from "@/components/utils/modals/news/Modal__showNews.vue";

export default {
  name: "FFMENewsItem",

  components: { modalNews },

  data: () => ({
    openNewsModalIsOpen: false,
  }),

  methods: {
    readNews() {
      if (this.news.LIST_NewsType && this.news.LIST_NewsType.SlugTypeNews === "direct_info") {
        window.open(this.news.NewsTexte, "_blank");

        return;
      }

      this.openNewsModalIsOpen = true;
    }
  },

  computed: {
    body() {
      return this.news.NewsTitre;
    },
    structureName() {
      return this.news?.STR_Structure?.NomStructure || "FFME";
    },
    posted_at() {
      return this.$moment(this.news.DateParution)
        .tz("Europe/Paris")
        .fromNow();
    },
  },

  props: {
    news: {
      type: Object,
    },
    showName: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style>
.v-dialog {
  margin: 0 !important;
}
.title_name {
  color: #0646a5 !important;
  font-family: Roboto;
  font-size: 19px;
}
</style>
