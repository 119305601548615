<template>
  <v-dialog
    v-if="isOpen"
    v-model="isOpen"
    @click:outside="closeModal()"
    max-width="900px"
  >
    <v-tabs value="slug" background-color="blue-dark" dark grow>
      <v-tabs-slider></v-tabs-slider>
      <v-tab class="font-weight-bold">Actualité: {{ structureName }}</v-tab>
      <v-icon class="close-icon" @click="closeModal">mdi-close</v-icon>
    </v-tabs>
    <div class="modal__content">
      <v-tabs-items>
        <v-card flat>
          <v-card-text>
            <div class="py-10 px-6">
              <div
                v-if="news.DOC_Document && news.DOC_Document.CheminDocument"
                class="mb-4"
              >
                <v-img
                  :src="news.DOC_Document.CheminDocument"
                  aspect-ratio="2.5"
                ></v-img>
              </div>
              <div class="d-flex justify-content-between">
                <p class="font-weight-bold">{{ news.NewsTitre }}</p>
                <span>{{
                  $moment(news.DateParution).format("DD/MM/YYYY")
                }}</span>
              </div>
              <div class="textarea">{{ news.NewsTexte }}</div>
            </div>
            <div class="text-center pb-10">
              <v-btn @click="closeModal()">Fermer</v-btn>
            </div>
          </v-card-text>
        </v-card>
      </v-tabs-items>
    </div>
  </v-dialog>
</template>

<script>
export default {
  components: {},
  data: () => ({}),

  async created() {
    // console.log(this.news);
  },

  methods: {
    closeModal() {
      this.$emit("onClose");
    },
  },

  computed: {
    structureName() {
      return this.news?.STR_Structure?.NomStructure || "FFME";
    },
  },

  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },

    news: {
      type: Object,
    },
  },
};
</script>
<style lang="scss" scoped>
.label_column {
  width: auto;
  label {
    margin-bottom: 0;
    padding-top: 5px;
    font-weight: 200;
    font-size: 0.8rem;
  }
}
.not-editable {
  font-size: 0.8rem;
  line-height: 30px;
}
input {
  border: 1px solid #dfe5f9;
  padding: 4px 5px;
  font-weight: 200;
  font-size: 0.8rem;
  width: 100%;
  max-width: 400px;

  &.is-invalid {
    border-color: red;
  }
  &:focus {
    outline: none;
  }

  &.full-width {
    max-width: 100%;
  }
}

.rounded-pill {
  padding: 4px 13px;
}
.textarea {
  white-space: pre-line;
  white-space: pre-wrap;
}

.close-icon {
  border-bottom: 2px solid white;
  cursor: pointer;
}
</style>
